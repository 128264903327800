import React from "react";
import logo_low_quality from "../images/icon_low_quality.webp";
import discord_logo from "../images/discord_logo.png";

function Navbar() {
  return (
    <div className="flex justify-between flex-row font-rale pt-3">
      <div className="flex flex-row pl-5 justify-center items-center gap-3">
        <img className="h-12 rounded-full" src={logo_low_quality} alt="" />
        <p className="font-semibold text-[20px]">LIS Values</p>
      </div>
      <div className="flex flex-row pr-5 justify-center items-center gap-5">
        <a className="font-semibold text-[20px]" href="/rankings">Rankings</a>
        <a className="font-semibold text-[20px]" href="/rankings">Trading</a>
        <a className="font-semibold text-[20px]" href="/rankings">Values</a>
        <img className="h-8 cursor-pointer" onClick={() => window.location.href="https://discord.com/invite/xj4NpXu2fW"} src={discord_logo} alt="" />
      </div>
    </div>
  );
}

export default Navbar;
