import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";

function App() {
  return (
    <>
      <Navbar />
      <div className="flex justify-center items-center">
        <div className="mt-16 text-center">
          <p className="font-bold text-4xl">Launch Into Space Simulator</p>
          <p className="text-[#737272] font-semibold mt-2">The #1 Value List</p>
        </div>
      </div>
    </>
  );
}

export default App;
